<template>
  <header
    id="header"
    :style="
      isChangeNavColor &&
      !gameListShow &&
      'border-bottom: 0.75px solid #f0f0f0;filter: drop-shadow(0 1.5px 24px rgba(0, 86, 123, 0.08));'
    "
    class="header"
  >
    <h1 class="left">
      <a href="/"  :style="`background-image: url(${require('@/assets/image/logo.png')})`">
        嗨喵悦动,嗨喵互动
        <!-- <img v-lazy="require('@/assets/image/logo.png')" alt="嗨喵悦动,嗨喵互动,logo"
      /> -->
    </a>
      <!-- <svg-icon iconClass="player"></svg-icon> -->
    </h1>
    <div class="middle">
      <el-menu
        @close="handleMenuClose"
        ref="menu"
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleNavOp"
        class="menu"
      >
        <el-menu-item index="home">
          <div
            @click="handleNavClick('/')"
            class="headerStyle"
            :class="activeIndex === 'home' && 'show-line'"
          >
            首页
            <div class="line2"></div>
          </div>
        </el-menu-item>
        <!-- 
        
          <el-submenu :class="resolveListShow && 'is-opened'" index="resolve" class="resolve">
          <template #title>
            <div :style="resolveListShow && 'color:#008DFF'" class="headerStyle" id="resolve-plan">解决方案</div>
          </template>
        -->
        <el-submenu :class="freeListShow && 'is-opened'" index="free" class="free">
          <template #title>
            <div :style="freeListShow && 'color:#008DFF'" class="headerStyle" id="free-use">
              免费试用
            </div>
          </template>
        </el-submenu>
        <!-- <el-submenu  index="wedding">
          <template slot="title">
            <div class="weddingItem">
              <img src="@/assets/image/wedding.png" class="weddingIcon" alt="嗨喵悦动,嗨喵互动,婚礼版" />
              婚礼版
            </div>
          </template>
          <el-menu-item class="no-padding" index="wedding-0">
            <div class="subItem">通用版</div>
          </el-menu-item>
          <el-menu-item  class="no-padding" index="wedding-1">
            <div class="subItem">中式婚礼版</div>
          </el-menu-item>
        </el-submenu> -->
        <!-- <el-submenu index="wedding">
          <template slot="title">
            <div class="sItem">
              <img
                src="@/assets/image/wedding.png"
                class="versionIcon"
                alt="嗨喵悦动,嗨喵互动,婚礼版"
              />
              婚礼版
            </div>
            <div class="subtitle">免</div>
          </template>
          <el-menu-item index="wedding-0">
            <div class="sItem">通用版</div>
          </el-menu-item>
          <el-menu-item index="wedding-1">
            <div class="sItem">中式婚礼版</div>
          </el-menu-item>
        </el-submenu> -->
        <!-- <el-menu-item index="company">
          <div class="weddingItem">
            <img src="@/assets/image/company.png" class="companyIcon" alt="嗨喵悦动,嗨喵互动,企业版" />
            企业版
          </div>
        </el-menu-item> -->
        <!-- <el-submenu   index="version">
          <template #title>
            <div class="headerStyle">更多版本</div>
          </template>
          <el-submenu index="brithday" class="birthdaySubMenu">
            <template #title>
              <div class="subMenu">
                <img src="@/assets/image/birthday.png" alt="嗨喵悦动,嗨喵互动,生日版" />
                <span>生日版</span>
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.2426 7.06064L6 2.818L1.75736 7.06064" stroke="rgba(0,0,0,0.4)" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </template>
            <el-menu-item index="birthday-0">
              <div class="subItem">通用版</div>
            </el-menu-item>
            <el-menu-item index="birthday-1">
              <div class="subItem">宝宝宴</div>
            </el-menu-item>
            <el-menu-item index="birthday-2">
              <div class="subItem">寿宴</div>
            </el-menu-item>
            <el-menu-item index="birthday-3">
              <div class="subItem">成人礼</div>
            </el-menu-item>
            <el-menu-item index="birthday-4">
              <div class="subItem">百露宴</div>
            </el-menu-item>
            <el-menu-item index="birthday-5">
              <div class="subItem">满月宴</div>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="school" class="birthdaySubMenu">
            <template #title>
              <div class="subMenu">
                <img src="@/assets/image/schoolicon.png" alt="嗨喵悦动,嗨喵互动,校园版" />
                <span>校园版</span>
                <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.2426 7.06064L6 2.818L1.75736 7.06064" stroke="rgba(0,0,0,0.4)" stroke-width="1.5"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </template>
            <el-menu-item index="school-0">
              <div class="subItem">毕业典礼</div>
            </el-menu-item>
            <el-menu-item index="school-1">
              <div class="subItem">谢师宴</div>
            </el-menu-item>
            <el-menu-item index="school-2">
              <div class="subItem">金榜题名</div>
            </el-menu-item>
          </el-submenu>
        </el-submenu> -->
        <el-menu-item index="package">
          <a @click.prevent="handleNavClick('/setmeal')" href="/setmeal">
            <div class="headerStyle" :class="activeIndex === 'package' && 'show-line'">
              婚礼套餐
            </div>
          </a>
        </el-menu-item>

        <el-submenu :class="gameListShow && 'is-opened'" index="produce" class="produce">
          <template #title>
            <div :style="gameListShow && 'color:#008DFF'" class="headerStyle" id="produce-service">
              产品服务
            </div>
          </template>
        </el-submenu>
        <el-menu-item index="partner">
          <a href="https://www.hudongmiao.com/course/activity10.html">
            <div class="headerStyle">活动中心</div>
          </a>
        </el-menu-item>
        <!-- <el-menu-item index="package" class="produce">
          <div class="headerStyle">
            产品服务
          </div>
        </el-menu-item> -->
        <!-- <el-menu-item index="partner">
          <a href="/hm-customer/index.html#/partnerSystem/login">
            <div class="headerStyle">嗨喵合伙人</div>
          </a>
        </el-menu-item> -->
        <el-submenu :class="resolveListShow && 'is-opened'" index="resolve" class="resolve">
          <template #title>
            <div :style="resolveListShow && 'color:#008DFF'" class="headerStyle" id="resolve-plan">
              解决方案
            </div>
          </template>
          <!-- <el-menu-item class="no-padding">
            <a href="/app" target="_blank">
              <div class="subItem">嗨喵APP</div>
            </a>
          </el-menu-item>
          <el-menu-item  class="no-padding">
            <a target="_blank" href="/hm-course/">
              <div class="subItem">嗨喵课程</div>
            </a>
          </el-menu-item>
          <el-menu-item  class="no-padding">
            <router-link class="subItem" :to="{ name: 'wxt' }" target="_blank">嗨喵微喜帖</router-link>
          </el-menu-item>
          <el-menu-item  class="no-padding">
            <a href="/game" target="_blank">
              <div class="subItem">嗨喵游戏</div>
            </a>
          </el-menu-item>
          <el-menu-item  class="no-padding">
            <a href="https://www.hunlitang.cn/" target="_blank">
              <div class="subItem">嗨喵婚礼堂</div>
            </a>
          </el-menu-item>
          <el-menu-item class="no-padding">
            <a target="_blank" href="https://ustatic.hudongmiao.com/joymewDesk/joymewDesk.exe">
              <div class="subItem">嗨喵桌面程序</div>
            </a>
          </el-menu-item>
          <el-menu-item class="no-padding">
            <a target="_blank" href="https://ustatic.joymew.com/browsers/ChromeStandaloneSetup64.exe">
              <div class="subItem">Google浏览器下载</div>
            </a>
          </el-menu-item> -->
        </el-submenu>

        <!-- <el-menu-item index="desk">
          <div class="headerStyle">活动中心</div>
        </el-menu-item> -->
        <!-- <el-menu-item index="version">
          更多版本
        </el-menu-item> -->
        <!-- <el-submenu index="birthday">
          <template slot="title">
            <div class="sItem">
              <img
                src="@/assets/image/birthday.png"
                class="versionIcon"
                alt="嗨喵悦动,嗨喵互动,生日版"
              />
              生日版
            </div>
          </template>
          <el-menu-item index="birthday-0">
            <div class="sItem">通用版</div>
          </el-menu-item>
          <el-menu-item index="birthday-1">
            <div class="sItem">宝宝宴</div>
          </el-menu-item>
          <el-menu-item index="birthday-2">
            <div class="sItem">寿宴</div>
          </el-menu-item>
          <el-menu-item index="birthday-3">
            <div class="sItem">成人礼</div>
          </el-menu-item>
          <el-menu-item index="birthday-4">
            <div class="sItem">百露宴</div>
          </el-menu-item>
          <el-menu-item index="birthday-5">
            <div class="sItem">满月宴</div>
          </el-menu-item>
        </el-submenu> -->

        <!-- <el-submenu index="school">
          <template slot="title">
            <div class="sItem">
              <img
                src="@/assets/image/schoolicon.png"
                class="versionIcon"
                alt="嗨喵悦动,嗨喵互动,校园版"
              />
              校园版
            </div>
          </template>
          <el-menu-item index="school-0">
            <div class="sItem">毕业典礼</div>
          </el-menu-item>
          <el-menu-item index="school-1">
            <div class="sItem">谢师宴</div>
          </el-menu-item>
          <el-menu-item index="school-2">
            <div class="sItem">金榜题名</div>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="version">
          更多版本
        </el-menu-item> -->
        <!-- <el-menu-item index="desk">桌面程序</el-menu-item> -->
        <!-- <el-menu-item index="wxt">
          <div class="line">
            微喜帖
            <div class="line2"></div></div
        ></el-menu-item> -->
        <!-- <el-menu-item index="partner">嗨喵合伙人</el-menu-item> -->
        
      </el-menu>
    </div>

    <div class="right">
      <div class="r-box">
        <div class="login" @click="openLrBox(0)" v-if="!token" :class="{ iframeEnv: isIframeEnv }">
          登录
        </div>
        <div class="register" @click="openLrBox(2)" v-if="!token">免费注册</div>
        <div
          class="applyActivity publicHvCenter"
          :class="{ iframeEnv: isIframeEnv }"
          v-if="token"
          @click="toApply"
        >
          创建活动
        </div>
        <div
          class="userInfo publicVCenter"
          :class="{ iframeEnv: isIframeEnv }"
          v-if="token"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
        >
          <img :src="headImg" class="headImg" alt="嗨喵悦动,嗨喵互动,用户头像" />
          <div :style="navState != 'white' && 'color: #fff;'" class="name">{{ userName }}</div>
        </div>
        <div
          class="userInfoSubMenu publicHvCenter"
          :class="{ ani: userInfoShow, iframeEnv: isIframeEnv }"
          v-if="userInfoSubMenuVisible"
          @mouseenter="handleMouseEnter2"
          @mouseleave="handleMouseLeave2"
        >
          <div class="item" @click="toUserCenter">我的账户</div>
          <div class="item" @click="logout">退出登录</div>
        </div>
        <!-- <div class="boxspace"></div> -->
      </div>
    </div>

    <lrBox @close="handleCloseLrBox" v-show="lrBoxVisible" ref="lrBoxDom" />
    <Transition name="el-zoom-in-top">
      <div v-show="freeListShow" class="free-container">
        <img
          :style="`left:${freeTriangleLeft}px`"
          src="@/assets/image/triangle.png"
          class="triangle"
          alt="嗨喵悦动,嗨喵互动,三角图标"
        />
        <div id="freeContent" class="content">
          <div class="flex-content" :style="`left:${fuContentLeft}px`">
            <div>
              <span><img src="@/assets/image/hlb.png" alt="嗨喵悦动,嗨喵互动,婚礼版" />婚礼版</span>
              <a
               target="_blank"
                class="wedding-common"
                href="https://screen.hudongmiao.com/#/?liveId=7b249edb6f5b420c82b31f485080b1a7&screenType=wedding"
              >
                <img src="@/assets/image/fire.png" alt="嗨喵悦动,嗨喵互动,婚礼版" />
                <span
                  >通用版<img src="@/assets/image/hot.png" alt="嗨喵悦动,嗨喵互动,婚礼版"
                /></span>
              </a>
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=c7b1e1c6f32a4d038699bc688a60a6c2"
                >中式婚礼版</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=209cecf81079463cbfc30db52c765292 ">订婚宴</a>
            </div>
            <div>
              <span><img src="@/assets/image/qyb.png" alt="嗨喵悦动,嗨喵互动,企业版" />企业版</span>
              <a target="_blank"
                href="https://screen.hudongmiao.com/#/?liveId=d6ce3429edd04a8aa51a4dea1be6348b&screenType=enterprise"
                >通用版</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=b7d5cb19d9094e778819f814200fb7e5">会销版</a>
              <a href="https://screen.hudongmiao.com/#/?liveId=c306c5dd43524842908bdc608a788fd5&screenType=enterprise" target="_blank">发布会</a>
              <a href="https://screen.hudongmiao.com/#/?liveId=6fedf59567704fb79dc04cd81e195be7&screenType=enterprise" target="_blank">展览展会</a>
            </div>
            <div>
              <span><img src="@/assets/image/srb.png" alt="嗨喵悦动,嗨喵互动,生日版" />生日版</span>
              <a  target="_blank" href="https://screen.hudongmiao.com/#/?liveId=90dee1f7763749749c19815c91108560"
                >通用版</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=d7ab02019f36404baed48145171fb93e"
                >宝宝宴</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=7921accf5ce242039dfc5b5ca2e4cc32"
                >寿宴</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=4ddd46ba20c04885a6ee5058a5ea42f7"
                >成人礼</a
              >
              <!-- <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=011ce8cccdaa4132a25cd9264895b5c2"
                >白露宴</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=1628cc6e935846e58fbf170f0d72d45d"
                >满月宴</a
              > -->
            </div>
            <div>
              <span><img src="@/assets/image/xyb.png" alt="嗨喵悦动,嗨喵互动,校园版" />校园版</span>
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=688f56dc4be84a57be45c1648590bb35"
                >毕业典礼</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=3830c1957bb94b009d945687b3a85768"
                >谢师宴</a
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=a780c85a90d84534ab15f958730b0368"
                >金榜题名</a
              >
            </div>
            <div>
              <span
                ><img
                  src="@/assets/image/gdbb.png"
                  alt="嗨喵悦动,嗨喵互动,更多版本"
                />更多版本</span
              >
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=3e738e6c8ec141bc8f224cdb2e7878d3">同学会</a>
              <a target="_blank" href="https://screen.hudongmiao.com/#/?liveId=461f34a76aa14171a9008dc2d969d735">乔迁宴</a>
              
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="el-zoom-in-top">
      <div v-show="resolveListShow" class="resolve-container">
        <img
          :style="`left:${resolveTriangleLeft}px`"
          src="@/assets/image/triangle.png"
          class="triangle"
          alt="嗨喵悦动,嗨喵互动,三角图标"
        />
        <div id="resolveContent" class="content">
          <div class="flex-content">
            <div>
              <img src="@/assets/image/hmapp.png" alt="嗨喵悦动,嗨喵互动,嗨喵App" />
              <div>
                <a href="/app/" target="_blank">
                  <span>嗨喵APP</span>
                  <span>档期作品记录，主持人的好帮手</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/hmkc.png" alt="嗨喵悦动,嗨喵互动,嗨喵课程" />
              <div>
                <a href="/hm-course/" target="_blank">
                  <span>嗨喵课程</span>
                  <span>海量教程，助您主持更成功</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/hmwxt.png" alt="嗨喵悦动,嗨喵互动,嗨喵微喜帖" />
              <div>
                <a href="/wxt/" target="_blank">
                  <span>嗨喵微喜帖</span>
                  <span>结婚电子邀请函，免费丰富好用</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/hmyx.png" alt="嗨喵悦动,嗨喵互动,嗨喵游戏" />
              <div>
                <a href="/game/" target="_blank">
                  <span>嗨喵游戏</span>
                  <span>热门微信小游戏，免费畅玩</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/hmhlt.png" alt="嗨喵悦动,嗨喵互动,嗨喵婚礼堂" />
              <div>
                <a href="https://www.hunlitang.cn/" target="_blank">
                  <span>嗨喵婚礼堂</span>
                  <span>大数据获客神器，订单源源不断</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/hmzmcx.png" alt="嗨喵悦动,嗨喵互动,嗨喵桌面程序" />
              <div>
                <a @click.prevent="downloadDesk" href="https://ustatic.hudongmiao.com/joymewDesk/joymewDesk.exe" target="_blank">
                  <span>嗨喵桌面程序</span>
                  <span>一键安装使用，互动便捷省心</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/hmds.png" alt="嗨喵悦动,嗨喵互动,嗨喵导师" />
              <div>
                <a href="/mentor/" target="_blank">
                  <span>嗨喵导师</span>
                  <span>大屏互动明星导师风采</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/ggllqxz.png" alt="嗨喵悦动,嗨喵互动,谷歌浏览器下载" />
              <div>
                <a @click.prevent="downloadBrowser" href="https://ustatic.joymew.com/browsers/ChromeStandaloneSetup64.exe" target="_blank">
                  <span>谷歌浏览器下载</span>
                  <span>官方推荐使用浏览器，快速又稳定</span>
                </a>
              </div>
            </div>
            <div>
              <img src="@/assets/image/hmhhr.png" alt="嗨喵悦动,嗨喵互动,谷歌浏览器下载" />
              <div>
                <a href="/hm-customer/index.html#/partnerSystem/login" target="_blank">
                  <span>嗨喵合伙人</span>
                  <span>合伙人专属后台</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <Transition name="el-zoom-in-top">
      <div v-show="gameListShow" class="game-container">
        <img
          :style="`left:${triangleLeft}px`"
          src="@/assets/image/triangle.png"
          class="triangle"
          alt="嗨喵悦动,嗨喵互动,三角图标"
        />
        <div id="gameContent" class="content">
          <div v-for="(i, i_) in psList" :key="i_">
            <span>
              <img :src="i.icon" alt="嗨喵悦动,嗨喵互动,图标">
              {{ i.title }}</span>
            <template
              v-if="i.list.length < 8"
            >
              <span @click="to($i.url)" v-for="($i, $i_) in i.list" :key="$i_">
              {{ $i.content }}</span
            >
            </template>
            <template
              v-else
            >
            <div>
              <div>
                <span @click="to($i.url)" v-for="($i, $i_) in i.list.slice(0,8)" :key="$i_">{{ $i.content }}</span>
              </div>
              <div>
                <span @click="to($i.url)" v-for="($i, $i_) in i.list.slice(8,16)" :key="$i_">{{ $i.content }}</span>
              </div>
            </div>
            </template>
          </div>
        </div>
      </div>
    </Transition>
  </header>
</template>

<script>
import lrBox from "@/components/lrBox.vue";
import { mapState } from "vuex";
import{getProductList}from '@/api/';
import {
  WEDDING,
  WEDDING_ZSHL,
  ENTERPRISE,
  BIRTH,
  MACDESK,
  WINDOWDESK,
  // SCHOOL,
  BABY,
  OLDBIRTHDAY,
  AGE_CEREMONY,
  GRADUATION,
  THANKS,
  NAME_ONBORAD,
  BLY,
  MYY,
  PATNER,
  // NORMAL_VERSION,
} from "@/assets/constant/default";
import { timeoutTask, isIframeEnv } from "@/utils/index";
import activity from "@/assets/image/activity.png";
import shake from "@/assets/image/shake.png";
import draw from "@/assets/image/draw.png";
import lucky from "@/assets/image/lucky.png";
import sign from "@/assets/image/sign.png";
import play from "@/assets/image/play.png";
import redpack from "@/assets/image/redpack.png";

let mouseOverFlag = false;
export default {
  name: "joymew-header",
  components: {
    lrBox,
  },
  
  data() {
    return {
      userInfoShow: false,
      isIframeEnv: false,
      userInfoSubMenuVisible: false,
      lrBoxVisible: false,
      activeIndex: "home",
      // 是否切换过导航栏颜色
      isChangeNavColor: false,
      list: [
        ["开场签到", "3D签到", "中式签到簿", "手写签到", "跑车签到", "启动仪式"],
        ["宏包游戏", "经典摇宏包", "点宏包", "喊宏包", "猜宏包"],
        ["幸运夺宝", "幸运小转盘", "转一转", "扭一扭", "争分夺秒", "开宝箱", "宏包墙"],
        [
          "摇一摇游戏",
          "经典赛车",
          "追新娘",
          "疯狂游泳",
          "猴子爬树",
          "飞龙在天",
          "兔飞猛进",
          "赛龙舟",
          "滑雪大冒险",
        ],
        [
          "划一划游戏",
          "数钞票",
          "切水果",
          "狼吞虎咽",
          "武松打虎",
          "谁是射手王",
          "兔子投篮",
          "游龙戏珠",
        ],
        [
          "活动抽奖",
          "3D抽奖",
          "地球抽奖",
          "巨幕抽奖",
          "名单抽奖",
          "转盘游戏",
          "卡牌抽奖",
          "图片抽奖",
        ],
      ],
      psList: [],
      listTemplate: {
        title: '',
        icon: '',
        list: [
          {
            content: '',
            url: ''
          }
        ]
      },
      iconList: [sign, redpack, lucky, shake, draw, activity, play],
      scrollTop: 0,
      gameListShow: false,
      resolveListShow: false,
      freeListShow: false,
      // 当前导航栏的状态 white表示导航栏背景为白色，否则为透明transparent
      navState: "white",
      // 三角形的左边距离
      triangleLeft: 0,
      resolveTriangleLeft: 0,
      freeTriangleLeft: 0,
      fuContentLeft: 0,
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      phone: (state) => state.phone,
      userName: (state) => state.userName,
      headImg: (state) => state.headImg,
    }),
  },

  methods: {
    handleMenuClose() {
      // alert("menu close")
    },
    getDirection(event, dom) {
      const rect = dom.getBoundingClientRect();
      const x = event.clientX;
      const y = event.clientY;

      let direction = "";

      const topDiff = y - rect.top;
      const bottomDiff = rect.bottom - y;
      const leftDiff = x - rect.left;
      const rightDiff = rect.right - x;

      // 判断哪个距离最小，即为鼠标移出的位置
      const minDiff = Math.min(topDiff, bottomDiff, leftDiff, rightDiff);

      switch (minDiff) {
        case topDiff:
          direction = "top";
          break;
        case bottomDiff:
          direction = "bottom";
          break;
        case leftDiff:
          direction = "left";
          break;
        case rightDiff:
          direction = "right";
          break;
      }

      return direction;
    },
    produceEnter() {},
    handleNavClick(path) {
      if (this.$route.path == path) {
      } else {
        window.open(path, "_self");
      }
    },
    to(src) {
      window.open(src);
    },
    open:window.open,
    handleNavOp(e) {
      let target;
      if (this.$route.name === "newhome") {
        target = "home";
      } else target = "package";
      switch (e) {
        case "home":
          this.$router.push("/");
          this.activeIndex = "home";
          break;
        case "wedding-0":
          window.open(WEDDING, "_blank");
          break;
        case "wedding-1":
          window.open(WEDDING_ZSHL, "_blank");
          break;
        case "company":
          window.open(ENTERPRISE, "_blank");
          break;
        // case 'birthday':
        case "birthday-0":
          window.open(BIRTH, "_blank");
          break;
        case "birthday-1":
          window.open(BABY, "_blank");
          break;
        case "birthday-2":
          window.open(OLDBIRTHDAY, "_blank");
          break;
        case "birthday-3":
          window.open(AGE_CEREMONY, "_blank");
          break;
        case "birthday-4":
          window.open(BLY, "_blank");
          break;
        case "birthday-5":
          window.open(MYY, "_blank");
          break;
        case "school-0":
          window.open(GRADUATION, "_blank");
          break;
        case "school-1":
          window.open(THANKS, "_blank");
          break;
        case "school-2":
          window.open(NAME_ONBORAD, "_blank");
          break;
        case "desk":
          this.downloadDesk();
          break;
        case "partner":
          // window.open(PATNER, '_self');
          break;
        // case 'wxt':
        //   this.$router.push({
        //     path: '/wxt',
        //   });
        //   this.activeIndex = 'wxt';

        //   break;

        default:
          console.log(e);
          break;
      }
      // alert(this.activeIndex)
      this.activeIndex = target;
      // this.activeIndex = '1';
    },
    downloadDesk() {
      const version = navigator.userAgent.toLowerCase();
      const mac = version.indexOf("mac");
      const os = version.indexOf("os");
      if (mac > 0 && os > 0) {
        window.open(MACDESK, "_blank");
      } else {
        window.open(WINDOWDESK, "_blank");
      }
    },
    downloadBrowser() {
      const version = navigator.userAgent.toLowerCase();
      const mac = version.indexOf("mac");
      const os = version.indexOf("os");
      if (mac > 0 && os > 0) {
        window.open(`https://ustatic.joymew.com/browsers/googlechromeSetupMacos.dmg`, "_blank");
      } else {
        window.open(`https://ustatic.joymew.com/browsers/ChromeStandaloneSetup64.exe`, "_blank");
      }
    },
    
    openLrBox(type) {
      this.lrBoxVisible = true;
      this.$nextTick(() => {
        this.$refs.lrBoxDom.openBoxByType(type);
      });
    },
    handleCloseLrBox() {
      this.lrBoxVisible = false;
    },
    toApply() {
      window.location.href = `${window.location.origin}/hm-customer/index.html#/app/myActivity/create`;
    },
    handleMouseEnter() {
      mouseOverFlag = true;
      this.userInfoSubMenuVisible = true;
      timeoutTask(() => {
        this.userInfoShow = true;
      }, 100);
    },
    handleMouseLeave() {
      mouseOverFlag = false;
      if (!this.userInfoSubMenuVisible) {
        return;
      }
      timeoutTask(() => {
        if (!mouseOverFlag) {
          this.userInfoShow = false;
          timeoutTask(() => {
            this.userInfoSubMenuVisible = false;
          }, 100);
        }
      }, 500);
    },
    handleMouseEnter2() {
      mouseOverFlag = true;
      this.userInfoSubMenuVisible = true;
      timeoutTask(() => {
        this.userInfoShow = true;
      }, 100);
    },
    handleMouseLeave2() {
      mouseOverFlag = false;
      if (!this.userInfoSubMenuVisible) {
        return;
      }
      timeoutTask(() => {
        if (!mouseOverFlag) {
          this.userInfoShow = false;
          timeoutTask(() => {
            this.userInfoSubMenuVisible = false;
          }, 100);
        }
      }, 500);
    },
    logout() {
      this.$store.commit("resetUserInfo");
      this.userInfoShow = false;
      this.userInfoSubMenuVisible = false;
      window.parent.postMessage("clearStorage", "*");
    },
    toUserCenter() {
      window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
      this.userInfoShow = false;
      this.userInfoSubMenuVisible = false;
    },
    onPageScroll() {
      return (this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop);
    },

    resetTrianglePosition() {
      let { width, x } = document.getElementById("produce-service").getBoundingClientRect();
      this.triangleLeft = x + width / 2;
      let rp = document.getElementById("resolve-plan").getBoundingClientRect();
      this.resolveTriangleLeft = rp.x + rp.width / 2;

      let fu = document.getElementById("free-use").getBoundingClientRect();
      this.freeTriangleLeft = fu.x + fu.width / 2;

      //与免费试用对齐
      this.fuContentLeft = fu.left;
    },
    changeBgColorToWhite() {
      document.documentElement.style.setProperty("--nav-color", "black");
        document.documentElement.style.setProperty("--nav-bg-color", "white");
        document.documentElement.style.setProperty("--nav-registry-bg-color", "#008DFF");
        document.documentElement.style.setProperty("--nav-registry-color", "white");
        this.navState = "white";
    }
  },

  async created() {
    mouseOverFlag = false;
    this.isIframeEnv = isIframeEnv();
    this.$store.commit("initUserInfo");
    window.addEventListener("message", this.handleMessage);
    const data = await getProductList();
    const{list}=data.data;
    /**
     * listTemplate: {
        title: '',
        icon: '',
        list: [
          {
            content: '',
            url: ''
          }
        ]
      },
     */
    var x = list.map(item => {
      return {
        title: item.name,
        icon:item.icon,
        list: item.course_list.map($ => ({
          url: $.course_url,
          content: $.title
        }))
      };
    });
    this.psList = Object.freeze(x);
    console.error(this.psList)
  },
  mounted() {
    
    document.querySelectorAll(".middle>.el-menu>li").forEach((item) => {
      item.addEventListener("mouseenter", function () {
        // document.querySelector('.aaaaaa').parentElement.parentElement.style.top = '72px'
      });
    });
    window.route = this.$route;

    window.addEventListener("resize", this.resetTrianglePosition);
    this.$nextTick(() => this.resetTrianglePosition());
    // setInterval(() => {
    //   console.log(this.activeIndex)
    // }, 1e3);
    const header = document.querySelector(".header");
    // alert(header)
    header.addEventListener("mouseenter", () => {
      document.documentElement.style.setProperty("--nav-color", "black");
      document.documentElement.style.setProperty("--nav-bg-color", "white");
      document.documentElement.style.setProperty("--nav-registry-bg-color", "#008DFF");
      document.documentElement.style.setProperty("--nav-registry-color", "white");
      // this.isChangeNavColor = false;
      this.navState = "white";
    });
    header.addEventListener("mouseleave", () => {
      if (document.documentElement.scrollTop > 10) return;
      document.documentElement.style.setProperty("--nav-color", "white");
      document.documentElement.style.setProperty("--nav-bg-color", "transparent");
      document.documentElement.style.setProperty("--nav-registry-bg-color", "white");
      document.documentElement.style.setProperty("--nav-registry-color", "#008DFF");
      // this.isChangeNavColor = false;
      this.navState = "";
    });

    const free = document.querySelector(".el-submenu.free");
    free.addEventListener("mouseenter", (e) => {
      this.freeListShow = true;
    });
    free.addEventListener("mouseleave", (e) => {
      const result = this.getDirection(e, free);
      if (result !== "bottom") {
        this.freeListShow = false;
      }
    });

    const resolve = document.querySelector(".el-submenu.resolve");
    resolve.addEventListener("mouseenter", (e) => {
      this.resolveListShow = true;
    });
    resolve.addEventListener("mouseleave", (e) => {
      const result = this.getDirection(e, resolve);
      if (result !== "bottom") {
        this.resolveListShow = false;
      }
    });

    const produce = document.querySelector(".el-submenu.produce");
    produce.addEventListener("mouseenter", (e) => {
      this.gameListShow = true;
    });
    produce.addEventListener("mouseleave", (e) => {
      const result = this.getDirection(e, produce);

      console.log(result);
      // alert(result)
      if (result !== "bottom") {
        this.gameListShow = false;
      }
    });

    const gameContent = document.querySelector("#gameContent");
    // alert(gameContent)
    gameContent.addEventListener("mouseleave", (e) => {
      this.gameListShow = false;
    });

    const resolveContent = document.querySelector("#resolveContent");
    resolveContent.addEventListener("mouseleave", (e) => {
      this.resolveListShow = false;
    });

    const freeContent = document.querySelector("#freeContent");
    freeContent.addEventListener("mouseleave", (e) => {
      this.freeListShow = false;
    });

    window.addEventListener("scroll", this.onPageScroll);
    const { path } = this.$route;
    if (path === "/") {
      this.activeIndex = "home";
    } else if (path.includes("wxt")) {
      this.activeIndex = "wxt";
    }

    if (path.includes("/setmeal")) {
      this.activeIndex = "package";
    }


    // window.addEventListener("resize", this.resetTrianglePosition);
  },
  changeIndex(e) {
    this.activeIndex = e;
  },
  beforeDestroy() {
    // this.unWatchScroll();
    window.removeEventListener("message", this.handleMessage);
    window.removeEventListener("resize", this.resetTrianglePosition);
  },
  watch: {
    gameListShow(value) {
      if(value && this.navState === '') {
        return this.changeBgColorToWhite();
      }
    },
    resolveListShow(value) {
      if(value && this.navState === '') {
        return this.changeBgColorToWhite();
      }
    },
    freeListShow(value) {
      if(value && this.navState === '') {
        return this.changeBgColorToWhite();
      }
    },
    activeIndex(newValue) {
      this.$refs.menu.activeIndex = newValue;
    },
    scrollTop(newTop) {
      if (this.gameListShow || this.resolveListShow || this.freeListShow) return;
      // console.log(newTop)
      if (newTop > 200 && !this.isChangeNavColor) {
        document.documentElement.style.setProperty("--nav-color", "black");
        document.documentElement.style.setProperty("--nav-bg-color", "white");
        document.documentElement.style.setProperty("--nav-registry-bg-color", "#008DFF");
        document.documentElement.style.setProperty("--nav-registry-color", "white");
        this.isChangeNavColor = true;
        this.navState = "white";
      }
      if (newTop < 10) {
        document.documentElement.style.setProperty("--nav-color", "white");
        document.documentElement.style.setProperty("--nav-bg-color", "transparent");
        document.documentElement.style.setProperty("--nav-registry-bg-color", "white");
        document.documentElement.style.setProperty("--nav-registry-color", "#008DFF");
        this.isChangeNavColor = false;
        this.navState = "";
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onPageScroll);
    window.removeEventListener("resize", this.resetTrianglePosition);
  },
};
</script>

<style lang="less" scoped>
::v-deep .is-opened .el-submenu__icon-arrow {
  color: #008dff !important;
}
.el-menu-item.is-active {
  font-weight: bold !important;
}

.free-container {
  position: fixed;  
  top: 61.5px;
  left: 0;
  width: 100%;
  height: calc(100vh - 61.5px);
  border-top: 0.75px solid #008dff;
  filter: drop-shadow(0 2.25px 2.25px rgba(0, 86, 123, 0.08));
  z-index: 99999999;

  .triangle {
    position: absolute;
    top: 0;
    width: 18px;
    height: 9px;
    transform: translateY(-100%);
  }

  .content {
    // height: 471px;
    background-color: white;
    display: flex;
    // justify-content: center;
    padding: 0 0 0 0;

    .flex-content {
      width: 100%;
      display: flex;
      padding: 32.25px 0 0 0;
      padding-bottom: 28.5px;
      // position: abp;
      padding-left: 371.0025px;
      
      // padding-right: 313.5px;
      // justify-content: space-between;
      div {
        width: 100.5px;
        display: flex;
        flex-direction: column;
        margin-right: 99.9975px;
        &:last-child {
          margin-right: 0;
        }
        > span {
          padding-bottom: 15px;
          margin-bottom: 32.25px;
          font-size: 15.997499999999999px;
          border-bottom: 0.9975px solid #e5e7eb;
          display: flex;
          align-content: center;

          img {
            width: 24px;
            height: 24px;
            margin-right: 4.5px;
          }
        }
        
        .wedding-common {
          position: relative;
          display: flex;
          align-items: center;
          > img {
            width: 15.997499999999999px;
            height: 15.997499999999999px;
          }

          span {
            position: relative;

            img {
              position: absolute;
              right: 0;
              top: 0;
              width: 15.75px;
              height: 6.75px;
              transform: translate(100%, -100%);
            }
          }
        }
        a:nth-child(2) {
          padding-top: 0;
        }
        .more {
          margin-top: auto;
          display: flex;
          align-items: center;
          color: #008dff;
          // transform: translateY(100%);
          padding: 0;
          img {
            width: 12.997499999999999px;
            height: 12px;
          }
        }
        a {
          padding: 13.5px 0;
          color: rgba(51, 51, 51, 1);
          font-size: 14.002500000000001px;
          transition: all 0.3s;

          &:hover {
            // background-color: rgba(0, 141, 255, .1);
            color: #008dff;
          }
        }
      }
    }
  }
}

.resolve-container {
  position: fixed;
  top: 61.5px;
  left: 0;
  width: 100%;
  height: calc(100vh - 61.5px);
  border-top: 0.75px solid #008dff;
  filter: drop-shadow(0 2.25px 2.25px rgba(0, 86, 123, 0.08));
  z-index: 99999999;

  .triangle {
    position: absolute;
    top: 0;
    width: 18px;
    height: 9px;
    transform: translateY(-100%);

    &.triangle-login {
      right: 639px;
    }
  }

  .content {
    // height: 471px;
    background-color: white;
    display: flex;
    justify-content: center;
    padding: 0 78px 0 99.9975px;

    .flex-content {
      display: flex;
      width: 957px;
      padding: 32.25px 0;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        padding: 12px;
        width: 0;
        flex: 0 0 270px;
        display: flex;
        height: 70.5px;
        transition: all 0.3s;

        &:hover {
          background-color: rgba(0, 141, 255, 0.1);
        }

        img {
          width: 32.25px;
          height: 32.25px;
          margin-right: 12px;
        }

        div {
          display: flex;
          flex-direction: column;

          a {
            height: 100%;
            display: flex;
            flex-direction: column;
            span {
              white-space: nowrap;
            }

            span:first-child {
              font-weight: bold;
              font-size: 15.997499999999999px;
              color: black;
            }

            span:last-child {
              margin-top: auto;
              color: rgba(0, 0, 0, 0.4);
              font-size: 14.002500000000001px;
            }
          }
        }
      }
    }
  }
}

.game-container {
  position: fixed;
  top: 61.5px;
  left: 0;
  width: 100%;
  height: calc(100vh - 61.5px);
  // background-color: black;
  border-top: 0.75px solid #008dff;
  z-index: 99999999;
  filter: drop-shadow(0 2.25px 2.25px rgba(0, 86, 123, 0.08));

  .triangle {
    position: absolute;
    right: 622.5px;
    top: 0;
    width: 18px;
    height: 9px;
    transform: translateY(-100%);

    &.triangle-login {
      right: 639px;
    }
  }

  .content {
    // height: 471px;
    background-color: white;
    display: flex;
    padding: 34.5px 159.9975px 75px 159.9975px;
    
    justify-content: space-between;

    > div {
      // margin-right: 80.0025px;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        margin-right: 30px;

        &:last-child {
          margin: 0;
        }
        div {
          display: flex;
          flex-direction: column;
          span {
          padding: 12px 0 ;
          color: #333;
          font-size: 14.002500000000001px;
          transition: all .3s;
          &:hover {
            color: #008dff;
            cursor: pointer;
            
          }
        }
        }

      }
      // margin-right: 87.9975px;
      > span {
        padding: 12px 0;
        color: #333;
        font-size: 14.002500000000001px;
        transition: all 0.3s;

        &:hover {
          color: #008dff;
          cursor: pointer;
          // background-color: rgba(0, 141, 255, .1);
        }
      }

      > span:first-child {
        font-size: 15.997499999999999px;
        color: #008dff;
        border-bottom: 0.9975px solid #e5e7eb;
        padding-bottom: 18.9975px;
        margin-bottom: 32.0025px;
        display: flex;
        align-items: center;

        // width: 99.9975px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 3.75px;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    > div:last-child {
      margin: 0;

      > span:first-child {
        font-size: 15.997499999999999px;
        color: #008dff;
        border-bottom: 0.9975px solid #e5e7eb;
        padding-bottom: 18.9975px;
        margin-bottom: 32.0025px;
        width: 99.9975px;
      }

      div {
        display: flex;

        div {
          margin-right: 30px;
          display: flex;
          flex-direction: column;

          span {
            padding: 12px 0;
            color: #333;
            font-size: 14.002500000000001px;
            transition: all 0.3s;

            &:hover {
              // background-color: rgba(0, 141, 255, 0.1);
              color: #008dff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-submenu__icon-arrow {
  margin-left: 7.5px !important;
  margin-top: 0.5625px !important;
}

::v-deep .el-menu-item:has(.weddingItem) {
  padding: 0 15px 0 calc(15px + 24px) !important;
}

// .el-menu-item.no-padding {
//   padding: 0 !important;
// }

// .el-menu-item:has(.no-padding) {
//   padding: 0 !important;
// }
// ::v-deep .el-submenu__title:has(.weddingItem) {
//   padding: 0 15px 0 calc(15px + 24px) !important;
// }
// .weddingItem:has(.companyIcon) {
//   margin-left: 15px;
// }
::v-deep .el-menu-item:has(.companyIcon) {
  padding: 0 15px 0 22.5px !important;
}

.headerStyle {
  // font-weight: bold;
  font-size: 15.997499999999999px;
  position: relative;

  &::after {
    position: absolute;
    width: 24px;
    height: 3px;
    // background: #ffcc4f;
    border-radius: 14.002500000000001px;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    bottom: 7.5px;
  }
}

.show-line {
  &::after {
    background: #008dff;
  }
}

.weddingItem {
  // margin-left: 21px;
  // width: 48.75px;
  // font-weight: bold;
  font-size: 15.997499999999999px;

  // font-weight: 400 !important;
  .weddingIcon {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // left: 11.25px;
  }

  .companyIcon {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // left: 11.25px;
  }
}

.birthdaySubMenu .arrow {
  width: 18px;
  height: 12.75px;
  transform: rotate(90deg) scale(0.7);
}

.subMenu {
  // width: 150px;
  // width: 126.9975px;
  // height: 45.9975px;
  // padding: 22.5px 15px;
  // font-weight: bold;
  box-sizing: border-box;
  font-size: 15.997499999999999px !important;
  // color: #333;
  display: flex;
  align-items: center;

  svg {
    transform: rotate(90deg) scale(1.25);
    // color: rebeccapurple;
    // width: 9px;
    // height: 9px;
  }

  span {
    display: inline-block;
    margin: 0 3.75px;
  }

  // font-weight: bold;
  img {
    width: 17.0025px;
    height: 18px;
  }

  &:hover {
    background: rgba(255, 204, 79, 0.1);
    // color: #FFCC4F;
  }
}

.subItem {
  // width: 200.0025px;
  // height: 45.9975px;
  // color: #919398;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.75px 15px;
  font-size: 14.002500000000001px;
  color: #919398;

  // transition: all .5s;
  &:hover {
    // background-color: rgba(0, 141, 255, .1);
    color: #008dff;
  }

  img {
    width: 17.0025px;
    height: 18px;
  }
}

.header {
  display: flex;
  // justify-content: space-between;
  height: 61.5px;
  align-items: center;
  padding: 0 159.9975px;
  position: fixed;
  background-color: var(--nav-bg-color);
  width: 100%;
  box-sizing: border-box;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease;
  // backdrop-filter: blur(15px);
  // overflow: hidden;
  
  .left {
    flex-shrink: 0;
    width: 71.25px;
    height: 36px;
    a{
      display: block;
      width: 71.25px;
      background-size: 71.25px 36px;
      background-repeat: no-repeat;
      overflow: hidden;
      height: 0;
      padding-top: 36px;
    }
  }

  .left img {
    width: 71.25px;
    height: 36px;
  }

  // .currentMenu {
  //   width: 108.75px;
  //   height: 33.75px;
  //   background: linear-gradient(0deg, #f94928 0%, #fc674d 100%);
  //   border-radius: 21.75px;
  //   font-size: 18px;
  //   font-weight: 600;
  //   color: rgba(255, 255, 255, 0.9);
  //   letter-spacing: 1.5px;
  //   text-shadow: 0px 2.25px 0.75px 0px #ed2719;
  //   // margin-left: 165px;
  //   cursor: pointer;
  //   position: absolute;
  //   left: 150px;
  // }
  .middle {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    // margin-left: 4;
    // margin-left: 207px;
    // align-items: center;
    margin-left: 36px;
    width: fit-content;

    // flex-grow: 1;
    .menu {


      // left: 231px;
      &.el-menu::v-deep {
        // width: 356.25px;
        // height: 39px !important;
        border-radius: 27px;
        border: 0.75px solid #ffffff;
        border: 0;
        background-color: transparent;
        // margin-left: 107.25px;
        // margin-right: 39.75px;
        display: flex;
        align-items: center;
        // justify-content: center;

        // width: 150px;
        .el-menu-item {
          // height: 100% !important;
          padding: 0 24px !important;
          font-size: 15.75px;
          color: var(--nav-color);
          background-color: unset;
          border-bottom: unset;
          // margin: 0 15px;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;

          .subtitle {
            width: 29.25px;
            height: 14.25px;
            font-size: 7.5px;
            line-height: 15px;
            color: white;
            font-weight: 400;
            text-align: center;
            position: absolute;
            right: -3.75px;
            top: 0px;
            background: url("~@/assets/image/subtitlebg.png") no-repeat;
            background-size: contain;
            background-position: center;
          }

          .sItem {
            // col;
            font-size: 15.997499999999999px;
            // text-align: center;
            width: 112.5px;
            // &:hover {
            //   color: #ffcc4f;
            //   background-color: rgba(255,204,79,0.10);
            // }
          }

          &.is-active {
            border-bottom: unset;
            color: #008dff;

            .line {
              // background-color: red.;
              // border-bottom: 2.25px solid #fc9632;
              .line2 {
                width: 24px;
                height: 3px;
                background-color: #008dff;
                margin: 0 auto;
              }
            }
          }

          &:hover {
            background-color: unset;
            color: #008dff;
          }

          .versionIcon {
            vertical-align: text-top;
            // width: 22.5px;
            height: 22.5px;
          }
        }

        .el-submenu {
          .subtitle {
            width: 29.25px;
            height: 14.25px;
            font-size: 7.5px;
            line-height: 15px;
            color: white;
            font-weight: 400;
            text-align: center;
            position: absolute;
            right: 15px;
            top: -0px;
            background: url("~@/assets/image/subtitlebg.png") no-repeat;
            background-size: contain;
            background-position: center;
          }

          .el-submenu__title {
            padding: 0 24px;
            font-size: 15.75px;
            color: var(--nav-color);
            display: flex;
            align-items: center;
            border-bottom: unset;

            .versionIcon {
              width: 22.5px;
              height: 22.5px;
              vertical-align: text-top;
            }

            .demonsIcon {
              width: 17.25px;
              height: 17.25px;
              margin-right: 3px;
            }

            .el-submenu__icon-arrow {
              font-size: 13.5px;
              color: var(--nav-color);
              font-weight: bold;
              margin-left: 30px;
            }

            &:hover {
              background-color: unset;
              color: #008dff;

              .el-submenu__icon-arrow {
                color: #008dff;
              }
            }
          }
        }
      }
    }
  }

  .right {
    margin-left: auto;
    display: flex;
    font-size: 19.5px;
    flex-shrink: 0;
    // width: 285px;
    color: #333;
    justify-content: flex-end;

    // line-height: 75px;
    .r-box {
      display: flex;
      // justify-content: center;
      align-items: center;
      position: relative;

      .login {
        cursor: pointer;
        font-size: 14.25px;
        font-weight: 400;
        color: var(--nav-registry-bg-color);
        // margin-left: 143.25px;
        margin-left: 42.75px;
        // &:hover {
        //   color: #fc9632;
        // }

        &.iframeEnv {
          margin-left: 0;
        }
      }

      .register {
        cursor: pointer;
        margin-left: 32.25px;
        width: 104.25px;
        height: 39.75px;
        background: #008dff;
        transition: all 0.3s ease;
        border-radius: 3.75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14.25px;
        font-weight: 400;
        color: white;
        // &:hover {
        //   color: #fc9632;
        // }
      }

      .applyActivity {
        font-size: 12px;
        font-weight: 500;
        background-color: #008dff;
        color: #fff;
        position: relative;
        // right: 88.5px;
        margin-right: 22.5px;
        margin-left: 22.5px;
        cursor: pointer;
        width: 114px;
        height: 33.75px;
        border-radius: 6px;

        &.iframeEnv {
          // right: 270.75px;
        }
      }

      .userInfo {
        flex-direction: column;
        cursor: pointer;

        // position: absolute;
        // right: 75px;
        //  height: 75px;
        .headImg {
          width: 37.5px;
          height: 37.5px;
          border-radius: 100%;
        }

        .name {
          font-size: 12px;
          color: #666;
          margin-top: 4.5px;
          width: 70.5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          // margin-right: 45px;
        }

        // .boxspace {
        //     width: 75px;
        // }
        &.iframeEnv {
          right: 201.75px;
        }
      }

      .userInfoSubMenu {
        width: 95.25px;
        height: 0;
        position: absolute;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 6px;
        top: 51px;
        right: -12.75px;
        transition: all 0.1s ease-out;
        overflow: hidden;
        z-index: 100;

        &.ani {
          opacity: 1;
          height: 72.75px;
        }

        .item {
          color: #787a8c;
          font-size: 13.5px;
          font-weight: 400;
          cursor: pointer;

          &:nth-child(1) {
            margin-bottom: 9px;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        &.iframeEnv {
          right: 185.25px;
        }
      }
    }
  }
}
</style>
