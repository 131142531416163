<template>
  <div class="lrBox publicHvCenter">
    <div class="shade"></div>
    <div class="box">
      <img
        src="@/assets/image/close.png"
        class="closeIcon"
        @click="closelrBox"
        alt="嗨喵悦动,嗨喵互动,关闭图标"
      />
      <img
        src="@/assets/image/corner.png"
        class="qrIcon"
        v-if="lrType === 1 || lrType === 2"
        @click="openBoxByType(0)"
      />
      <div class="slogan">
        <img
          src="https://ustatic.hudongmiao.com/guanwang/img/login.png"
          alt="嗨喵悦动,嗨喵互动,登录"
        />
      </div>
      <div class="qrcodeForm publicVCenter" v-if="lrType === 0">
        <div class="welcome">Welcome</div>
        <div class="tit">二维码登录</div>
        <div class="qrcodeBox publicHvCenter">
          <img :src="qrcodePath" v-show="qrcodePath" alt="嗨喵悦动,嗨喵互动,二维码登录" />
          <i class="el-icon-loading" v-show="!qrcodePath"></i>
        </div>
        <div class="tip">请使用微信扫码登录</div>
        <div class="otherLoginEntry" @click="openBoxByType(1)">其他方式登录</div>
        <!-- <router-link to="service" target="_blank">
          <div class="loginTip publicHCetner">
            若登录则表示已阅读并同意本网站的<label>用户协议</label>
          </div>
        </router-link> -->
          <a href="/service" target="_blank">
            <div class="loginTip publicHCetner">
              若登录则表示已阅读并同意本网站的<label>用户协议</label>
            </div>
          </a>
      </div>
      <div class="loginForm publicVCenter" v-if="lrType === 1">
        <div class="chooseType publicVCenter">
          <div class="item" @click="openBoxByType(2)">注册</div>
          <div class="item active">登录</div>
        </div>
        <div class="phoneInput">
          <el-input placeholder="手机号" v-model="phone" maxlength="11">
            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
          </el-input>
        </div>
        <div class="passwordInput">
          <el-input placeholder="密码" v-model="password" show-password maxlength="20">
            <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
          </el-input>
        </div>
        <div class="forgetPwdEntry" @click="openBoxByType(3)">忘记密码</div>
        <div class="loginBtn publicHvCenter" @click="handleLogin">立即体验</div>
        <div class="otherLoginEtnry publicHCenter" @click="openBoxByType(0)">二维码登录</div>
        <!-- <router-link to="service" target="_blank">
          <div class="loginTip publicHCetner">
            若登录则表示已阅读并同意本网站的<label>用户协议</label>
          </div>
        </router-link> -->
          <a href="/service" target="_blank">
            <div class="loginTip publicHCetner">
              若登录则表示已阅读并同意本网站的<label>用户协议</label>
            </div>
          </a>
      </div>
      <div class="registerForm publicVCenter" v-if="lrType === 2">
        <div class="chooseType publicVCenter">
          <div class="item active">注册</div>
          <div class="item" @click="openBoxByType(1)">登录</div>
        </div>
        <div class="phoneInput">
          <el-input placeholder="手机号" v-model="phone" maxlength="11">
            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
          </el-input>
        </div>
        <div class="passwordInput">
          <el-input placeholder="密码" v-model="password" show-password maxlength="20">
            <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
          </el-input>
        </div>
        <div class="inviteCodeInput">
          <el-input placeholder="邀请码" v-model="inviteCode" maxlength="20">
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </div>
        <div class="validateCodeInput publicVCenter">
          <el-input placeholder="短信验证码" v-model="valiCode" maxlength="20">
            <i slot="prefix" class="el-input__icon el-icon-lollipop"></i>
          </el-input>
          <div class="getValiCodeBtn" @click="getValidate('1')">
            {{ isCd ? cd : "获取验证码" }}
          </div>
        </div>
        <div class="registerBtn publicHvCenter" @click="register">立即注册</div>
        <div class="otherLoginEtnry publicHCenter" @click="openBoxByType(0)">二维码登录</div>
        <!-- <router-link to="service" target="_blank">
          <div class="registerTip publicHCetner">
            若登录则表示已阅读并同意本网站的<label>用户协议</label>
          </div>
        </router-link> -->
        <a href="/service" target="_blank">
          <div class="registerTip publicHCetner">
            若登录则表示已阅读并同意本网站的<label>用户协议</label>
          </div>
        </a>
      </div>
      <div class="resetForm publicVCenter" v-if="lrType === 3">
        <div class="tit">重置密码</div>
        <div class="phoneInput">
          <el-input placeholder="手机号" v-model="phone" maxlength="11">
            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
          </el-input>
        </div>
        <div class="validateCodeInput publicVCenter">
          <el-input placeholder="短信验证码" v-model="valiCode" maxlength="20">
            <i slot="prefix" class="el-input__icon el-icon-lollipop"></i>
          </el-input>
          <div class="getValiCodeBtn" @click="getValidate('2')">
            {{ isCd ? cd : "获取验证码" }}
          </div>
        </div>
        <div class="passwordInput">
          <el-input placeholder="请输入新密码" v-model="password" show-password maxlength="20">
            <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
          </el-input>
        </div>
        <div class="resetBtn publicHvCenter" @click="changePwd">确认修改</div>
        <div class="otherLoginEtnry publicHCenter" @click="openBoxByType(0)">二维码登录</div>
        <!-- <router-link to="service" target="_blank">
          <div class="resetTip publicHCetner">
            若登录则表示已阅读并同意本网站的<label>用户协议</label>
          </div>
        </router-link> -->
        <a href="/service" target="_blank">
          <div class="resetTip publicHCetner">
            若登录则表示已阅读并同意本网站的<label>用户协议</label>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getQrcode,
  getLoginStatus,
  scanLogin,
  inputLogin,
  register,
  sendCode,
  resetPassword,
} from "@/api/index";
import { timeoutTask } from "@/utils/index";
import { HEADIMG } from "@/assets/constant/default";

let loopLock = false;
let btnLock = false;
export default {
  name: "lrBox",
  components: {},
  data() {
    return {
      lrType: 0, // 0:二维码登录 1: 手机号登录 2: 注册 3: 重置密码
      openId: "",
      ext: "",
      codeId: "",
      phone: "",
      password: "",
      inviteCode: "",
      valiCode: "",
      qrcodePath: "",
      isCd: false,
      cd: 60,
    };
  },
  watch: {
    lrType: function lrType(newVal) {
      if (newVal !== 0) {
        loopLock = true;
      }
    },
  },
  created() {
    loopLock = false;
    btnLock = false;
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    closelrBox() {
      this.$emit("close");
      loopLock = true;
    },
    openBoxByType(type) {
      if (type === 0) {
        this.lrType = 0;
        this.requestQrcode();
      } else if (type === 1) {
        this.lrType = 1;
      } else if (type === 2) {
        this.lrType = 2;
      } else if (type === 3) {
        this.lrType = 3;
      }
    },
    requestQrcode() {
      if (btnLock) {
        return;
      }
      btnLock = true;
      getQrcode()
        .then((res) => {
          console.log(res);
          btnLock = false;
          this.qrcodePath = res.WXQRCodeUrl;
          return res.loginKey;
        })
        .then((res2) => {
          console.log(res2);
          loopLock = false;
          this.loopRequestLoginStatus(res2);
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    loopRequestLoginStatus(pLoginKey) {
      if (loopLock) {
        return;
      }
      getLoginStatus(pLoginKey)
        .then((res) => {
          console.log(res);
          if (res.data.val === "0") {
            timeoutTask(() => {
              this.loopRequestLoginStatus(res.data.loginKey);
            }, 2000);
          } else if (res.data.val === "1") {
            loopLock = true;
            this.requestScanLogin(res.data.token);
          } else if (res.data.val === "2") {
            loopLock = true;
            this.$message("您还未拥有账号，请注册!");
            this.openId = res.data.openId;
            this.openBoxByType(2);
          } else {
            loopLock = true;
            this.$message.error("登录失败!");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    requestScanLogin(pToken) {
      scanLogin(pToken)
        .then((res) => {
          console.log(res);
          if (res.token) {
            window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
            this.$store.commit("updateUserInfo", {
              userName: res.emcee_name,
              headImg: res.avator,
              phone: res.phonenumber,
              token: res.token,
            });
            if (window.parent) {
              window.parent.postMessage(`token:${res.token}`, "*");
            }
          } else if (res.code === "500") {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleLogin() {
      if (!this.phone) {
        this.$message.error("请输入手机号!");
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error("手机号格式错误!");
        return;
      }
      if (!this.password) {
        this.$message.error("请输入密码!");
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      inputLogin({
        phone: this.phone,
        password: this.password,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.token) {
            window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
            this.$store.commit("updateUserInfo", {
              userName: res.emcee_name,
              headImg: res.avator,
              phone: res.phonenumber,
              token: res.token,
            });
            if (window.parent) {
              window.parent.postMessage(`token:${res.token}`, "*");
            }
          } else if (res.code === "500") {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    getValidate(pSource) {
      if (this.isCd) {
        return;
      }
      if (!this.phone) {
        this.$message.error("请输入手机号!");
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error("手机号格式错误!");
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      sendCode({
        phone: this.phone,
        source: pSource,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === "200") {
            this.ext = res.data.ext;
            this.codeId = res.data.codeId;
            this.$message({
              message: res.msg,
              type: "success",
            });
            if (!this.isCd) {
              this.isCd = true;
              this.cd = 60;
              this.countDown();
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    countDown() {
      if (this.cd === 0) {
        this.isCd = false;
        return;
      }
      this.cd -= 1;
      timeoutTask(() => {
        this.countDown();
      }, 1000);
    },
    changePwd() {
      if (!this.phone) {
        this.$message.error("请输入手机号!");
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error("手机号格式错误!");
        return;
      }
      if (!this.valiCode) {
        this.$message.error("请输入短信验证码!");
        return;
      }
      if (!this.password) {
        this.$message.error("请输入新密码!");
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      resetPassword({
        phone: this.phone,
        password: this.password,
        codeId: this.codeId,
        ext: this.ext,
        valiCode: this.valiCode,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === "200") {
            this.handleLogin();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    register() {
      if (!this.phone) {
        this.$message.error("请输入手机号!");
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error("手机号格式错误!");
        return;
      }
      if (!this.password) {
        this.$message.error("请输入密码!");
        return;
      }
      if (!this.inviteCode) {
        this.$message.error("请输入邀请码!");
        return;
      }
      if (!this.valiCode) {
        this.$message.error("请输入短信验证码!");
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      register({
        phone: this.phone,
        password: this.password,
        inviteCode: this.inviteCode,
        valiCode: this.valiCode,
        ext: this.ext,
        codeId: this.codeId,
        openId: this.openId,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === "500" || res.code === "400") {
            this.$message.error(res.errMsg);
          } else if (res.code === "200") {
            window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
            this.$store.commit("updateUserInfo", {
              userName: res.user_name,
              headImg: res.headimg_url || HEADIMG,
              phone: this.phone,
              token: res.token,
            });
            if (window.parent) {
              window.parent.postMessage(`token:${res.token}`, "*");
            }
          } else {
            this.$message.error("注册失败!");
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.lrBox {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .box {
    width: 629.25px;
    height: 495.75px;
    display: flex;
    position: relative;
    transform: scale(0.9);
    .closeIcon {
      width: 24px;
      height: 24px;
      position: absolute;
      right: -30px;
      top: -30px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .qrIcon {
      width: 69.75px;
      height: 58.5px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .slogan {
      width: 280.5px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .qrcodeForm {
      width: 348.75px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;
      .welcome {
        font-size: 36px;
        font-weight: bold;
        color: #373742;
        position: relative;
        margin-top: 30px;
        &::before {
          content: "";
          display: block;
          width: 49.5px;
          height: 1.5px;
          background: linear-gradient(139deg, #fec84e 0%, #feaf40 100%);
          position: absolute;
          left: -57.75px;
          top: 24px;
        }
        &::after {
          content: "";
          display: block;
          width: 49.5px;
          height: 1.5px;
          background: linear-gradient(139deg, #fec84e 0%, #feaf40 100%);
          position: absolute;
          right: -57.75px;
          top: 24px;
        }
      }
      .tit {
        font-weight: 500;
        color: #373742;
        font-size: 18px;
        margin-top: 36px;
      }
      .qrcodeBox {
        width: 216.75px;
        height: 216.75px;
        border-radius: 3px;
        border: 0.75px solid #feb342;
        margin-top: 21px;
        .el-icon-loading {
          font-size: 31.5px;
          color: #feb342;
        }
        img {
          width: 198.75px;
          height: 198.75px;
        }
      }
      .tip {
        color: #83838b;
        font-weight: 400;
        font-size: 18px;
        margin-top: 17.25px;
      }
      .otherLoginEntry {
        font-size: 13.5px;
        font-weight: 400;
        color: #83838b;
        margin-top: 21px;
        cursor: pointer;
        &:hover {
          color: #febd48;
        }
      }
      .loginTip {
        width: 100%;
        margin-top: 15px;
        font-size: 12px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;
        label {
          font-weight: 600;
          margin-left: 4.5px;
        }
        &:hover {
          color: #febd48;
        }
      }
    }
    .loginForm {
      width: 348.75px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;
      padding: 75px 36px 0 36px;
      .chooseType {
        width: 100%;
        .item {
          font-weight: 500;
          color: #969696;
          font-size: 18px;
          cursor: pointer;
          &.active {
            font-weight: 500;
            color: #373742;
            margin-left: 30px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .phoneInput {
        margin-top: 37.5px;
        width: 100%;
      }
      .passwordInput {
        margin-top: 33.75px;
        width: 100%;
      }
      .el-input::v-deep {
        font-size: 13.5px;
        .el-input__inner {
          height: 37.5px;
          &:focus {
            border-color: #febd48;
          }
        }
      }
      .forgetPwdEntry {
        width: 100%;
        margin-top: 22.5px;
        font-weight: 400;
        color: #feb041;
        font-size: 13.5px;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .loginBtn {
        width: 235.5px;
        height: 51px;
        background-image: url("../assets/image/button.png");
        background-size: 100% 100%;
        color: #fefdfb;
        font-weight: 500;
        font-size: 18px;
        margin-top: 41.25px;
        padding-bottom: 9px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .otherLoginEtnry {
        font-weight: 400;
        color: #83838b;
        font-size: 12px;
        width: 100%;
        margin-top: 12.75px;
        cursor: pointer;
        &:hover {
          color: #febd48;
        }
      }
      .loginTip {
        width: 100%;
        margin-top: 44.25px;
        font-size: 12px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;
        label {
          font-weight: 600;
          margin-left: 4.5px;
        }
        &:hover {
          color: #febd48;
        }
      }
    }
    .registerForm {
      width: 348.75px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;
      padding: 75px 36px 0 36px;
      .chooseType {
        width: 100%;
        .item {
          font-weight: 500;
          color: #969696;
          font-size: 18px;
          cursor: pointer;
          &.active {
            font-weight: 500;
            color: #373742;
            margin-right: 30px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .phoneInput {
        margin-top: 37.5px;
        width: 100%;
      }
      .passwordInput {
        margin-top: 15px;
        width: 100%;
      }
      .inviteCodeInput {
        margin-top: 15px;
        width: 100%;
      }
      .validateCodeInput {
        margin-top: 15px;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        .el-input::v-deep {
          width: 150px;
        }
        .getValiCodeBtn {
          font-weight: 400;
          font-size: 13.5px;
          color: #feb041;
        }
      }
      .el-input::v-deep {
        font-size: 13.5px;
        .el-input__inner {
          height: 37.5px;
          &:focus {
            border-color: #febd48;
          }
        }
      }
      .registerBtn {
        width: 235.5px;
        height: 51px;
        background-image: url("../assets/image/button.png");
        background-size: 100% 100%;
        color: #fefdfb;
        font-weight: 500;
        font-size: 18px;
        margin-top: 22.5px;
        padding-bottom: 9px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .otherLoginEtnry {
        font-weight: 400;
        color: #83838b;
        font-size: 12px;
        width: 100%;
        margin-top: 12.75px;
        cursor: pointer;
        &:hover {
          color: #febd48;
        }
      }
      .registerTip {
        width: 100%;
        margin-top: 28.5px;
        font-size: 12px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;
        label {
          font-weight: 600;
          margin-left: 4.5px;
        }
        &:hover {
          color: #febd48;
        }
      }
    }
    .resetForm {
      width: 348.75px;
      height: 100%;
      flex-direction: column;
      background: #f9f9f9;
      padding: 75px 36px 0 36px;
      .tit {
        width: 100%;
        font-weight: 500;
        color: #373742;
        font-size: 18px;
      }
      .phoneInput {
        margin-top: 37.5px;
        width: 100%;
      }
      .validateCodeInput {
        margin-top: 15px;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        .el-input::v-deep {
          width: 150px;
        }
        .getValiCodeBtn {
          font-weight: 400;
          font-size: 13.5px;
          color: #feb041;
        }
      }
      .passwordInput {
        margin-top: 15px;
        width: 100%;
      }
      .el-input::v-deep {
        font-size: 13.5px;
        .el-input__inner {
          height: 37.5px;
          &:focus {
            border-color: #febd48;
          }
        }
      }
      .resetBtn {
        width: 235.5px;
        height: 51px;
        background-image: url("../assets/image/button.png");
        background-size: 100% 100%;
        color: #fefdfb;
        font-weight: 500;
        font-size: 18px;
        margin-top: 22.5px;
        padding-bottom: 9px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .otherLoginEtnry {
        font-weight: 400;
        color: #83838b;
        font-size: 12px;
        width: 100%;
        margin-top: 12.75px;
        cursor: pointer;
        &:hover {
          color: #febd48;
        }
      }
      .resetTip {
        width: 100%;
        margin-top: 28.5px;
        font-size: 12px;
        font-weight: 400;
        color: #83838b;
        cursor: pointer;
        label {
          font-weight: 600;
          margin-left: 4.5px;
        }
        &:hover {
          color: #febd48;
        }
      }
    }
  }
}
</style>
